import React, {
	useState,
	useEffect,
	useMemo,
	useContext,
	createContext,
} from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'
import moment from 'moment-timezone'
import { useCookies } from 'react-cookie'
import network, {
	authenticator,
	authenticatorApi,
	api,
	hadesApi,
	adminHadesApi,
	setToken,
} from '../lib/network'
const {
	cloudEnabled,
	subscriber,
	subscriber_id,
	admin_subscriber,
	admin_subscriber_id,
} = network
const authContext = createContext()

const _setHadesToken = async _ => {
	if (!cloudEnabled()) return
	await api
		.get('/hades_token', {
			params: {
				subscriber: subscriber(),
				subscriber_id: subscriber_id(),
			},
		})
		.then(response => {
			let { tkn } = response.data
			setToken(hadesApi, tkn)
		})

	if (!admin_subscriber() || !admin_subscriber_id()) return
	await api
		.get('/hades_token', {
			params: {
				subscriber: admin_subscriber(),
				subscriber_id: admin_subscriber_id(),
			},
		})
		.then(response => {
			let { tkn } = response.data
			setToken(adminHadesApi, tkn)
		})
}

/**
 *  Provider component that wraps the app and makes auth object
 *  -   available to any child component that calls useAuth()
 */
export function ProvideAuth({ children }) {
	const auth = useProvideAuth()
	return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

/**
 * Hook for child components to get the auth object
 */
export const useAuth = () => {
	return useContext(authContext)
}

/**
 * Provider hook that creates auth object and handles state
 */
function useProvideAuth() {
	const [error, setError] = useState(null)
	const [user, setUser] = useState(null)
	const [authenticated, setAuthenticated] = useState(false)
	const [app, setApp] = useState(null)
	const [subscription, setSubscription] = useState(null)
	const [fetching, setFetching] = useState(false)
	const [cookies, setCookies, removeCookies] = useCookies(['login', 'app'])

	const signin = ({ appCode, email, password, defaultRoute }) => {
		setFetching(true)
		authenticator
			.post(`/app-signin/${appCode}`, { email, password })
			.then(({ data = {} }) => {
				const { apps, ...rest } = data
				for (let app of apps) {
					setCookies(
						app._id,
						{
							...app,
						},
						{
							path: '/',
							expires: new Date(
								moment().add(1, 'M').format('YYYY-MM-DDTHH:mm:ssZ'),
							),
						},
					)
				}
				setCookies(
					'login',
					{
						...rest,
						defaultRoute,
					},
					{
						path: '/',
						expires: new Date(
							moment().add(1, 'M').format('YYYY-MM-DDTHH:mm:ssZ'),
						),
					},
				)
				setError(null)
			})
			.catch(error => {
				setFetching(false)
				setError({
					title: 'Login Fallito',
					description: error?.response?.data?.message || 'Errore sconosciuto',
				})
			})
	}

	const chooseApp = async (app = {}) => {
		setFetching(true)
		setCookies(
			'app',
			{
				...app,
			},
			{
				path: '/',
				expires: new Date(moment().add(1, 'M').format('YYYY-MM-DDTHH:mm:ssZ')),
			},
		)
	}

	const signup = (email, password) => {
		//TODO signup
	}

	const signout = () => {
		for (let k in cookies) {
			removeCookies(k, { path: '/' })
		}
	}

	const sendPasswordResetEmail = email => {
		//TODO reset email
	}

	const confirmPasswordReset = (code, password) => {
		//TODO confirm reset
	}
	const consumeError = _ => setError(null)

	useDeepCompareEffect(() => {
		let { login, app, ...rest } = cookies
		let apps = []
		for (let k in rest) {
			const _app = rest[k]
			if (!_app?._id || !_app?.name || !_app?.token) continue
			apps.push(_app)
		}

		if (login?._id) {
			setToken(authenticatorApi, login.token)
			setUser({
				...login,
				apps,
			})
			setFetching(false)
		} else {
			setUser(null)
		}
	}, [cookies['login'] || {}])

	useDeepCompareEffect(() => {
		let appCookie = cookies['app']

		const updateToken = async app => {
			try {
				setToken(authenticator, app.token)
				setToken(api, app.token)
				setApp(app)
				setFetching(false)
				await _setHadesToken()
			} catch (error) {
				setError({
					title: 'Cloud connection Failure',
					description: error?.response?.data?.message || 'Errore sconosciuto',
				})
			}
		}
		if (appCookie?.token) updateToken(appCookie)
		else {
			setApp(null)
		}
	}, [cookies['app'] || {}])

	useDeepCompareEffect(
		_ => {
			let loginCookie = cookies['login']
			let appCookie = cookies['app']
			if (loginCookie?._id && appCookie?.token) setAuthenticated(true)
			else setAuthenticated(false)
		},
		[cookies['login'] || {}, cookies['app'] || {}],
	)

	useDeepCompareEffect(
		_ => {
			const subscription = async _ => {
				try {
					await authenticator
						.get('/app-subscription')
						.then(res => setSubscription(res.data))
				} catch (error) {
					setError({
						title: 'Errore recupero sottoscrizione',
						description: error?.response?.data?.message || 'Errore sconosciuto',
					})
				}
			}
			if (app?._id) subscription()
		},
		[app || {}],
	)

	// Return the user object and auth methods
	return {
		user,
		app,
		subscription,
		authenticated,
		fetching,
		signin,
		signup,
		signout,
		sendPasswordResetEmail,
		confirmPasswordReset,
		chooseApp,
		error,
		consumeError,
	}
}
