import React, { useState, useMemo, useEffect } from 'react'
import useSWR from 'swr'
import { navigate } from 'hookrouter'
import { api } from '@syn/core/lib/network'

import JSONInput from 'react-json-editor-ajrm'
import { useDisclosure, useToast } from '@chakra-ui/react'
import {
	SimpleGrid,
	Box,
	Text,
	IconButton,
	Flex,
	Input,
	Button,
	Heading,
	Textarea,
} from '@chakra-ui/react'

import {
	Drawer,
	DrawerOverlay,
	DrawerContent,
	DrawerHeader,
	DrawerBody,
	DrawerCloseButton,
} from '@chakra-ui/react'

import {
	Modal,
	ModalOverlay,
	ModalHeader,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
} from '@chakra-ui/react'
import {
	ViewIcon,
	DeleteIcon,
	ArrowUpDownIcon,
	ExternalLinkIcon,
} from '@chakra-ui/icons'

import { Table, Tooltipable } from '@syn/ui/components'

import { ObjectDetails } from 'pages'
const pluralize = require('pluralize')

export const ObjectList = ({ collection = '' }) => {
	const toast = useToast()
	const modalDisclosure = useDisclosure()
	const drawerDisclosure = useDisclosure()

	const [search, setSearch] = useState()
	const [content, setContent] = useState()
	const [selectedId, setSelectedId] = useState()
	const [size, setSize] = useState('xl')

	let singular_collection = pluralize.singular(collection)
	const params = useMemo(
		() => ({
			params: {
				name: singular_collection,
			},
		}),
		[singular_collection],
	)
	let { data: schemaData } = useSWR([`/baseobjects`, params])
	const dataParams = useMemo(
		() => ({
			params: {
				search,
			},
		}),
		[search],
	)
	const { data, error, mutate } = useSWR(
		collection ? [`/${collection}`, dataParams] : null,
	)
	let [row] = data || []
	let keys = Object.keys(row || {})

	if (schemaData) {
		schemaData = schemaData[0]?.schemaDefinition
	}

	useEffect(() => {
		let postData = {}
		for (let key in schemaData || {}) {
			postData[key] = ''
		}
		setContent(postData)
	}, [schemaData])

	return (
		<Flex w='100%' h='100%' direction='column'>
			<Flex justify='flex-end' p={1}>
				<Flex>
					<Input
						placeholder='Cerca'
						onChange={e => setSearch(e.target.value)}
					/>
				</Flex>

				<Button variant='ghost' onClick={modalDisclosure.onOpen}>
					Add content
				</Button>
			</Flex>
			<Table.Container fetching={!data}>
				<Table.Header>
					{keys?.map((value, index) => (
						<Tooltipable bold key={index} text={`${value}`} />
					))}
				</Table.Header>
				{data?.map(value => {
					return (
						<Table.Row
							key={value?._id}
							onClick={_ => {
								console.log('open', value?._id)
								setSelectedId(value?._id)
								drawerDisclosure.onOpen()
							}}>
							{keys?.map(key => {
								let item = value[key]

								let isObj = typeof item === 'object' && item !== null
								return (
									<div
										style={{
											whiteSpace: 'nowrap',
											overflow: 'hidden',
											textOverflow: 'ellipsis',
										}}
										key={key}>
										{isObj ? 'Object' : `${item}`}
									</div>
								)
							})}
						</Table.Row>
					)
				})}
			</Table.Container>

			<Drawer
				onClose={drawerDisclosure.onClose}
				isOpen={drawerDisclosure.isOpen}
				size={size}>
				<DrawerOverlay />
				<DrawerContent>
					<Box p='4px'>
						<IconButton
							icon={<ArrowUpDownIcon />}
							variant='ghost'
							onClick={() => setSize(size === 'xl' ? 'full' : 'xl')}
						/>
						<IconButton
							icon={<ExternalLinkIcon />}
							variant='ghost'
							onClick={() =>
								navigate(`/collections/${collection}/${selectedId}`)
							}
						/>
					</Box>

					<DrawerCloseButton />

					<DrawerHeader>{singular_collection}</DrawerHeader>
					<DrawerBody overflow='auto'>
						<ObjectDetails
							mutate={mutate}
							onClose={drawerDisclosure.onClose}
							collection={singular_collection}
							id={selectedId}
						/>
					</DrawerBody>
				</DrawerContent>
			</Drawer>
			<Modal
				onClose={modalDisclosure.onClose}
				size='xl'
				isOpen={modalDisclosure.isOpen}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Create Content</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<JSONInput
							id='addContant'
							viewOnly={true}
							theme='light_mitsuketa_tribute'
							colors={{ default: '#000000' }}
							height='300px'
							placeholder={schemaData}
						/>
						<Flex h='20px' />
						<JSONInput
							id='postContant'
							waitAfterKeyPress={4000}
							theme='light_mitsuketa_tribute'
							colors={{ default: '#000000' }}
							placeholder={content}
							height='300px'
							onChange={obj => setContent(obj.jsObject)}
						/>
					</ModalBody>
					<ModalFooter>
						<Button
							onClick={() => {
								api
									.post(`/${singular_collection}`, content)
									.then(_ => {
										toast({
											title: 'Oggetto Creato',
											status: 'success',
											isClosable: true,
											position: 'bottom-right',
										})
										mutate()
										modalDisclosure.onClose()
									})
									.catch(error => {
										toast({
											title: 'Errore richiesta',
											description: error,
											status: 'error',
											isClosable: true,
											position: 'bottom-right',
										})
									})
							}}>
							Create
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</Flex>
	)
}

export default ObjectList
