import React, { useState, useEffect } from 'react'
import { useAuth } from '@syn/core/hooks/use-auth-single'
import { validateEmail, validatePassword } from '@syn/core/lib/validation'
import styled from '@emotion/styled'

import { Flex, Button, Input } from '@chakra-ui/react'
import { FormControl, FormErrorMessage, useToast } from '@chakra-ui/react'

import bg from '../assets/safe.svg'
const Container = ({ className, children }) => (
	<Flex className={className} size='100%' p='20%' justify='center'>
		{children}
	</Flex>
)
const BgContainer = styled(Container)`
	&::before {
		content: '';
		background-image: url('${bg}');
		background-size: contain;
		background-repeat: no-repeat;
		background-position: bottom;
		position: absolute;
		top: 0px;
		right: 0px;
		bottom: 0px;
		left: 0px;
		opacity: 0.2;
	}
`
const Login = ({ tenant, defaultRoute = '/' }) => {
	const toast = useToast()

	const [email, setEmail] = useState('')
	const [emailValidation, setEmailValidation] = useState()
	const [password, setPassword] = useState('')
	const [passwordValidation, setPasswordValidation] = useState()

	console.log('AUTH use-auth-single', useAuth())
	const { user, signin, error, consumeError } = useAuth()

	useEffect(
		_ => {
			if (!error) return

			consumeError()
			toast({
				title: error?.title,
				description: error?.description,
				status: 'error',
				isClosable: true,
				position: 'bottom-right',
			})
		},
		[toast, error, consumeError],
	)

	const handleChange = ({ target: { id, value } }) => {
		switch (id) {
			case 'email':
				return setEmail(value)
			case 'password':
				return setPassword(value)
			default:
				break
		}
	}

	const handleBlur = ({ target: { id, value } }) => {
		switch (id) {
			case 'email':
				return setEmailValidation(validateEmail(value))
			case 'password':
				return setPasswordValidation(validatePassword(value))
			default:
				break
		}
	}

	const handleLogin = () => {
		let emailError = validateEmail(email)
		let pswError = validatePassword(password)
		setEmailValidation(emailError)
		setPasswordValidation(pswError)

		if (emailError || pswError) {
			return
		}
		signin({ tenant, email, password, defaultRoute })
	}

	return (
		<BgContainer>
			<Flex direction='column'>
				<form>
					<FormControl isInvalid={!!emailValidation} mb={2}>
						<Input
							id='email'
							name='email'
							type='email'
							autoComplete='email'
							placeholder='email@email.cloud'
							{...inputStyle}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
						<FormErrorMessage>{emailValidation}</FormErrorMessage>
					</FormControl>
					<FormControl isInvalid={!!passwordValidation} mb={2}>
						<Input
							id='password'
							name='password'
							type='password'
							autoComplete='current-password'
							placeholder='password'
							{...inputStyle}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
						<FormErrorMessage>{passwordValidation}</FormErrorMessage>
					</FormControl>
				</form>
				<Button {...submitStyle} onClick={handleLogin}>
					Login
				</Button>
			</Flex>
		</BgContainer>
	)
}

const inputStyle = {
	flex: '1',
	py: 2,
	px: 4,
	rounded: 'md',
	bg: 'gray.100',
	borderWidth: '1px',
	_hover: { borderColor: 'gray.200', bg: 'gray.200' },
	_focus: {
		outline: 'none',
		bg: 'white',
		boxShadow: 'outline',
		borderColor: 'gray.300',
	},
}

const submitStyle = {
	bg: 'teal.500',
	py: 2,
	px: 4,
	mt: 8,
	rounded: 'md',
	fontWeight: 'semibold',
	color: 'white',
	_hover: { bg: 'teal.600' },
	_focus: { boxShadow: 'outline' },
}

export default Login
