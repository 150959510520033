import React from 'react'
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
} from '@chakra-ui/react'
import { Button } from '@chakra-ui/react'

const Edit = ({
	title,
	disclosure: { isOpen, onClose },
	onEdit,
	children,
	...otherProps
}) => {
	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			blockScrollOnMount={false}
			closeOnOverlayClick={false}
			scrollBehavior='inside'
			{...otherProps}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>{title}</ModalHeader>
				<ModalCloseButton />
				<ModalBody>{children}</ModalBody>

				<ModalFooter>
					<Button colorScheme='blue' mr={3} onClick={onClose}>
						Annulla
					</Button>
					<Button
						variant='ghost'
						onClick={() => {
							onEdit()
							onClose()
						}}>
						Modifica
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}

export default Edit
