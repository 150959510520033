import React from 'react'
import { api } from '@syn/core/lib/network'
import {
	AlertDialog,
	AlertDialogOverlay,
	AlertDialogContent,
	AlertDialogHeader,
	AlertDialogBody,
	AlertDialogFooter,
	Button,
} from '@chakra-ui/react'

import { useToast } from '@chakra-ui/react'

const Delete = ({
	mutate,
	collection,
	id,
	params,
	title,
	text,
	disclosure: { isOpen, onClose },
}) => {
	const toast = useToast()
	return (
		<AlertDialog isOpen={isOpen} onClose={onClose}>
			<AlertDialogOverlay />
			<AlertDialogContent>
				<AlertDialogHeader fontSize='lg' fontWeight='bold'>
					{title}
				</AlertDialogHeader>

				<AlertDialogBody>{text}</AlertDialogBody>

				<AlertDialogFooter>
					<Button onClick={onClose}>Annulla</Button>
					<Button
						colorScheme='red'
						ml={3}
						onClick={_ => {
							onClose()
							api
								.delete(`${collection}/${id}`, { params })
								.then(_ => {
									mutate()
									toast({
										title: 'Oggetto Cancellato',
										status: 'success',
										isClosable: true,
										position: 'bottom-right',
									})
								})
								.catch(error => {
									toast({
										title: 'Errore richiesta',
										description:
											error?.response?.data?.message || 'Errore sconosciuto',
										status: 'error',
										isClosable: true,
										position: 'bottom-right',
									})
								})
						}}>
						Elimina
					</Button>
				</AlertDialogFooter>
			</AlertDialogContent>
		</AlertDialog>
	)
}

export default Delete
