export const validateEmail = value => {
	if (!value || value.trim() === '') {
		return 'Email is required'
	}
	if (!/^\w+([.-|\+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
		return 'Email is not valid'
	}
	return null
}

export const validatePassword = value => {
	if (!value || value.trim() === '') {
		return 'Password is required'
	}
	return null
}
