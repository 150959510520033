import React from 'react'
import { Flex, Heading } from '@chakra-ui/react'
import {
	Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverHeader,
	PopoverBody,
	PopoverArrow,
	PopoverCloseButton,
} from '@chakra-ui/react'
import { FiInfo } from 'react-icons/fi'
const Entry = ({ vertical, label, tooltip, children, ...otherProps }) =>
	vertical ? (
		<Flex
			p='20px 0px'
			direction='column'
			justify='space-around'
			{...otherProps}>
			<Flex position='relative'>
				<Heading as='h2' size='sm'>
					{label}
				</Heading>
				{tooltip && (
					<Popover placement='top'>
						<PopoverTrigger>
							<Flex ml='4px' mt='-4px'>
								<FiInfo size={24} color='black' />
							</Flex>
						</PopoverTrigger>
						<PopoverContent>
							{label && (
								<PopoverHeader fontWeight='semibold'>{label}</PopoverHeader>
							)}

							<PopoverArrow />
							<PopoverCloseButton />
							<PopoverBody>{tooltip}</PopoverBody>
						</PopoverContent>
					</Popover>
				)}
			</Flex>
			<Flex mt={2} w='100%'>
				{children}
			</Flex>
		</Flex>
	) : (
		<Flex
			p='20px 0px'
			justifyContent='space-between'
			flexGrow={1}
			wrap='flex-wrap'
			{...otherProps}>
			{label && (
				<Flex w='30%' minW='100px' position='relative'>
					<Heading as='h2' size='sm'>
						{label}
					</Heading>
					{tooltip && (
						<Popover placement='top'>
							<PopoverTrigger>
								<Flex ml='4px' mt='-4px'>
									<FiInfo size={24} color='black' />
								</Flex>
							</PopoverTrigger>
							<PopoverContent>
								<PopoverHeader fontWeight='semibold'>{label}</PopoverHeader>
								<PopoverArrow />
								<PopoverCloseButton />
								<PopoverBody>{tooltip}</PopoverBody>
							</PopoverContent>
						</Popover>
					)}
				</Flex>
			)}
			<Flex w={label ? '70%' : '100%'} justify='flex-end'>
				{children}
			</Flex>
		</Flex>
	)
export default Entry
