import axios from 'axios'
export let authenticator = axios.create({})
export let authenticatorApi = axios.create({})
export let api = axios.create({})
export let appsApi = axios.create({})
export let hadesApi = axios.create({})
export let adminHadesApi = axios.create({})

export const setToken = (ref, token) =>
	(ref.defaults.headers.common['Authorization'] = `Bearer ${token}`)

let subscriber = null
let subscriber_id = null
let admin_subscriber = null
let admin_subscriber_id = null
let cloudEnabled = false

export default {
	authenticatorUrl: url => {
		if (url) {
			authenticator.defaults.baseURL = url
			authenticatorApi.defaults.baseURL = url
		}
		return authenticator.defaults.baseURL
	},
	instanceUrl: url => {
		if (url) {
			api.defaults.baseURL = url
			appsApi.defaults.baseURL = url
		}
		return api.defaults.baseURL
	},
	cloudUrl: url => {
		if (url) {
			hadesApi.defaults.baseURL = url
			adminHadesApi.defaults.baseURL = url
		}
		return hadesApi.defaults.baseURL
	},
	subscriber: (_ = subscriber) => (subscriber = _),
	subscriber_id: (_ = subscriber_id) => (subscriber_id = _),
	admin_subscriber: (_ = admin_subscriber) => (admin_subscriber = _),
	admin_subscriber_id: (_ = admin_subscriber_id) => (admin_subscriber_id = _),
	cloudEnabled: (_ = cloudEnabled) => (cloudEnabled = _),
}
