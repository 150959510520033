import React from 'react'
import useRequireAuth from '@syn/core/hooks/use-require-auth'
import { useAuth } from '@syn/core/hooks/use-auth.js'

import { Flex, Spinner } from '@chakra-ui/react'

const AppWrapper = ({ auth = useAuth, loginPath = '/login', children }) => {
	const fetching = useRequireAuth(auth, loginPath)
	if (fetching) {
		return (
			<Flex h='100%' w='100%' align='center' justify='center'>
				<Spinner />
			</Flex>
		)
	}

	return (
		<Flex h='100%' w='100%' direction='column'>
			{children}
		</Flex>
	)
}

export default AppWrapper
