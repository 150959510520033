import React from 'react'
import { useAuth } from '@syn/core/hooks/use-auth'
import useWindowSize, { breakpoints } from '@syn/core/hooks/use-window-size'
import { Flex, Box, Heading, Text, Button } from '@chakra-ui/react'
import {
	Drawer,
	DrawerBody,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
} from '@chakra-ui/react'

import Breadcrumb from './Breadcrumb'
import Tooltipable from './Tooltipable'
import { FiLogOut, FiMenu } from 'react-icons/fi'
import { useDisclosure } from '@chakra-ui/react'

const PageWrapper = ({
	auth = useAuth,
	title,
	topBar,
	topBarMobile,
	botBar,
	botBarMobile,
	usrMenu,
	children,
}) => {
	const { signout } = auth()
	const { isOpen, onOpen, onClose } = useDisclosure()

	let { width } = useWindowSize()
	if (width <= breakpoints.md) {
		return (
			<Flex h='100%' w='100%' direction='column'>
				<Flex
					position='fixed'
					bg='white'
					zIndex={100}
					h='64px'
					w='100%'
					shadow='md'
					justify='space-between'>
					<Flex align='center'>
						<Button colorScheme='transparent' variant='ghost' onClick={onOpen}>
							<FiMenu size={24} color='black' />
						</Button>
						<Heading mr={2} p='10px' fontFamily='cursive'>
							<Tooltipable maxW={200} text={title} />
						</Heading>
					</Flex>
					<Flex pr={2}>
						<Box alignSelf='center'>{usrMenu}</Box>
					</Flex>
				</Flex>
				<Drawer onClose={onClose} isOpen={isOpen} placement='left'>
					<DrawerOverlay>
						<DrawerContent>
							<DrawerCloseButton />
							<DrawerHeader>
								<Box>
									<Heading mr={2} p='10px' fontFamily='cursive'>
										<Tooltipable maxW={200} text={title} />
									</Heading>
									<Breadcrumb />
								</Box>
							</DrawerHeader>
							<DrawerBody>
								<Flex w='100%' direction='column'>
									{topBarMobile}
								</Flex>
							</DrawerBody>
							<DrawerFooter>
								<Flex w='100%' direction='column'>
									{botBarMobile}
									<Button
										w='100%'
										justifyContent='start'
										colorScheme='transparent'
										variant='ghost'
										onClick={_ => signout()}>
										<FiLogOut size={24} color='black' />
										<Text ml='10px'>Logout</Text>
									</Button>
								</Flex>
							</DrawerFooter>
						</DrawerContent>
					</DrawerOverlay>
				</Drawer>
				<Flex
					h='calc(100% - 64px)'
					mt='64px'
					w='100%'
					direction='column'
					container={document.body}>
					{children}
				</Flex>
			</Flex>
		)
	}
	return (
		<Flex h='100%' w='100%' direction='column'>
			<Flex
				position='fixed'
				bg='white'
				zIndex={100}
				h='64px'
				w='100%'
				justify='space-between'
				shadow='md'>
				<Flex align='center'>
					<Heading size='lg' mr={2} p='10px' fontFamily='cursive'>
						<Tooltipable maxW={200} text={title} />
					</Heading>
					<Breadcrumb />
				</Flex>
				<Flex pr={2}>
					<Box alignSelf='center'>{usrMenu}</Box>
				</Flex>
			</Flex>
			<Flex h='calc(100% - 64px)' mt='64px'>
				<Flex
					position='fixed'
					bg='white'
					zIndex={99}
					w='64px'
					h='calc(100% - 64px)'
					pt='40px'
					shadow='md'
					direction='column'
					justify='space-between'>
					<Flex w='100%' direction='column' align='center'>
						{topBar}
					</Flex>
					<Flex w='100%' direction='column' align='center' pb={10}>
						{botBar}
						<Button
							w='100%'
							p='0px'
							colorScheme='transparent'
							variant='ghost'
							onClick={_ => signout()}>
							<FiLogOut size={24} color='black' />
						</Button>
					</Flex>
				</Flex>
				<Flex
					w='calc(100% - 64px)'
					h='100%'
					ml='64px'
					direction='column'
					container={document.body}>
					{children}
				</Flex>
			</Flex>
		</Flex>
	)
}

export default PageWrapper
