import React from 'react'
import { A } from 'hookrouter'
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react'
import { ChevronRightIcon } from '@chakra-ui/icons'

import Tooltipable from './Tooltipable'
import { capitalize } from '@syn/core/lib/utils'

const _split = (path = '') => {
	if (!path || path === '') return []

	let splitted = path.split('/')
	const to = splitted.join('/')
	const label = splitted.pop()
	return [..._split(splitted.join('/')), { label, to }]
}

const Brc = props => {
	let brcs = _split(window.location.pathname)
	return (
		<Breadcrumb
			fontSize='0.9rem'
			spacing='8px'
			separator={<ChevronRightIcon color='gray.500' />}>
			<BreadcrumbItem>
				<BreadcrumbLink as={A} href='/'>
					Home
				</BreadcrumbLink>
			</BreadcrumbItem>
			{brcs.map((value, index) => {
				return (
					<BreadcrumbItem key={index} isCurrentPage={brcs.length === index}>
						<BreadcrumbLink maxW='100px' as={A} href={value.to}>
							<Tooltipable text={capitalize(value.label)} />
						</BreadcrumbLink>
					</BreadcrumbItem>
				)
			})}
		</Breadcrumb>
	)
}
export default Brc
