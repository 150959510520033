import { useState, useEffect } from 'react'
import { theme } from '@chakra-ui/react'
export default function useWindowSize() {
	const [windowSize, setWindowSize] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	})

	useEffect(() => {
		function handleResize() {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			})
		}

		// Add event listener
		window.addEventListener('resize', handleResize)

		// Call handler right away so state gets updated with initial window size
		handleResize()

		// Remove event listener on cleanup
		return () => window.removeEventListener('resize', handleResize)
	}, []) // Empty array ensures that effect is only run on mount

	return windowSize
}
export const breakpoints = Object.assign(
	...Object.keys(theme?.breakpoints || {})?.map(key => {
		let value = parseFloat(theme?.breakpoints[key])
		return { [key]: value * 16 }
	}),
)
