import React from 'react'
import { useRoutes, navigate, usePath } from 'hookrouter'
import useSWR, { SWRConfig } from 'swr'
import { Global, css } from '@emotion/react'

import network, { authenticator, api } from '@syn/core/lib/network'
import { ProvideAuth, useAuth } from '@syn/core/hooks/use-auth-single'
import { AppWrapper, PageWrapper } from '@syn/ui/components'

import {
	ChakraProvider,
	Box,
	Text,
	Badge,
	Button,
	Divider,
	Tooltip,
} from '@chakra-ui/react'

import {
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	MenuGroup,
	MenuDivider,
} from '@chakra-ui/react'
import { Avatar } from '@chakra-ui/react'

import {
	FiPlus,
	FiUsers,
	FiDatabase,
	FiList,
	FiSettings,
	FiExternalLink,
} from 'react-icons/fi'

import Login from '@syn/login/single'
import Users from '@syn/users/users-single'
import { ObjectList, ObjectDetails } from 'pages'

const pluralize = require('pluralize')
network.instanceUrl('https://trico-be.syncode.cloud')
network.cloudEnabled(false)

// console.log = _ => null
// console.debug = _ => null

const Collections = props => {
	const { data } = useSWR(`/baseobjects`)
	return (
		data?.map((value, index) => (
			<Button
				key={index}
				variant='ghost'
				onClick={() => {
					navigate(`/collections/${pluralize(value?.name)}`)
				}}>
				{value?.name}
			</Button>
		)) || null
	)
}
const router = {
	[`/`]: () => (
		<Wrapper>
			<Text m='20px'>TRICO ADMINISTRATION</Text>
		</Wrapper>
	),
	[`/new*`]: () => <Wrapper>NEW</Wrapper>,
	[`/schemas*`]: () => <Wrapper>SCHEMAS</Wrapper>,
	['/collections']: () => (
		<Wrapper>
			<Collections />
		</Wrapper>
	),
	[`/collections/:collection`]: ({ collection }) => (
		<Wrapper>
			<ObjectList collection={collection} />
		</Wrapper>
	),
	[`/collections/:collection/:id`]: ({ collection, id }) => (
		<Wrapper>
			<ObjectDetails collection={collection} id={id} />
		</Wrapper>
	),
	[`/users*`]: () => (
		<Wrapper>
			<Users />
		</Wrapper>
	),
	[`/permissions*`]: () => <Wrapper>PERMISSIONS</Wrapper>,
	[`/login`]: () => <Login tenant='trico' defaultRoute='/' />,
}

const Wrapper = ({ children }) => {
	return (
		<PageWrapper
			auth={useAuth}
			title={'Trico'}
			topBar={<TopButtons />}
			topBarMobile={<TopButtonsMobile />}
			botBar={<BotButtons />}
			botBarMobile={<BotButtonsMobile />}
			usrMenu={<UserMenu />}>
			{children}
		</PageWrapper>
	)
}

const UserMenu = props => {
	const { user } = useAuth()
	let { data: userData } = useSWR([
		user?._id ? `/user/${user?._id}` : null,
		null,
		authenticator,
	])
	let role = userData?.roles?.filter(x => x !== user?._id)[0] || 'Nessun ruolo'

	return (
		<Menu>
			<MenuButton as={Button} variant='ghost' colorScheme='transparent'>
				<Avatar name={`${userData?.name} ${userData?.lastname}`} />
			</MenuButton>
			<MenuList pt={0} right={2} width='200px'>
				<Box p={5}>
					<Text mt={4} fontWeight='bold'>
						{userData?.name} {userData?.lastname}
						<Badge ml={3} fontSize='xs'>
							{role}
						</Badge>
					</Text>
				</Box>
				<MenuGroup title='Profile'>
					<MenuItem
						onClick={_ => {
							navigate(`/users/${user?._id}`)
						}}>
						My Account
					</MenuItem>
				</MenuGroup>
				<MenuDivider />
				<MenuGroup title='Help'>
					<MenuItem>Docs</MenuItem>
					<MenuItem>FAQ</MenuItem>
				</MenuGroup>
			</MenuList>
		</Menu>
	)
}
const TopButtons = () => {
	let path = usePath()
	const bg = route =>
		path?.startsWith(route) ? 'rgba(180, 180, 180, 0.25)' : 'transparent'
	return (
		<>
			<Tooltip label='New Collection' fontSize='md'>
				<Button
					disabled
					w='100%'
					p='0px'
					variant='ghost'
					bg={bg('/new')}
					_active={{
						bg: 'grey.100',
					}}
					onClick={_ => navigate('/new')}>
					<FiPlus size={24} color='black' />
				</Button>
			</Tooltip>
			<Divider />

			<Tooltip label='Schemas' fontSize='md'>
				<Button
					disabled
					w='100%'
					p='0px'
					variant='ghost'
					bg={bg('/schemas')}
					_active={{
						bg: 'grey.100',
					}}
					onClick={_ => navigate('/schemas')}>
					<FiDatabase size={24} color='black' />
				</Button>
			</Tooltip>
			<Divider />

			<Tooltip label='Collections' fontSize='md'>
				<Button
					w='100%'
					p='0px'
					variant='ghost'
					bg={bg('/collections')}
					_active={{
						bg: 'grey.100',
					}}
					onClick={_ => navigate('/collections')}>
					<FiList size={24} color='black' />
				</Button>
			</Tooltip>
			<Divider />
		</>
	)
}
const TopButtonsMobile = () => {
	let path = usePath()
	const bg = route =>
		path?.startsWith(route) ? 'rgba(180, 180, 180, 0.25)' : 'transparent'
	return (
		<>
			<Button
				disabled
				w='100%'
				justifyContent='start'
				variant='ghost'
				bg={bg('/new')}
				_active={{
					bg: 'grey.100',
				}}
				onClick={_ => navigate('/new')}>
				<FiPlus size={24} color='black' />
				<Text ml='10px'>New</Text>
			</Button>
			<Divider />

			<Button
				disabled
				w='100%'
				justifyContent='start'
				variant='ghost'
				bg={bg('/schemas')}
				_active={{
					bg: 'grey.100',
				}}
				onClick={_ => navigate('/schemas')}>
				<FiDatabase size={24} color='black' />
				<Text ml='10px'>Schemas</Text>
			</Button>
			<Divider />

			<Button
				w='100%'
				justifyContent='start'
				variant='ghost'
				bg={bg('/collections')}
				_active={{
					bg: 'grey.100',
				}}
				onClick={_ => navigate('/collections')}>
				<FiList size={24} color='black' />
				<Text ml='10px'>Collections</Text>
			</Button>
			<Divider />
		</>
	)
}

const BotButtons = () => {
	let path = usePath()
	const bg = route =>
		path?.startsWith(route) ? 'rgba(180, 180, 180, 0.25)' : 'transparent'

	return (
		<>
			<Tooltip label='Utenti' fontSize='md'>
				<Button
					w='100%'
					p='0px'
					variant='ghost'
					bg={bg('/users')}
					_active={{
						bg: 'grey.100',
					}}
					onClick={_ => navigate('/users')}>
					<FiUsers size={24} color='black' />
				</Button>
			</Tooltip>
			<Divider />

			<Tooltip label='Permissions' fontSize='md'>
				<Button
					disabled
					w='100%'
					p='0px'
					variant='ghost'
					bg={bg('/permissions')}
					_active={{
						bg: 'grey.100',
					}}
					onClick={_ => navigate('/permissions')}>
					<FiSettings size={24} color='black' />
				</Button>
			</Tooltip>
			<Divider />

			<Tooltip label='Images' fontSize='md'>
				<Button
					w='100%'
					p='0px'
					variant='ghost'
					_active={{
						bg: 'grey.100',
					}}
					onClick={_ =>
						window.open(
							'https://cloud.digitalocean.com/spaces/trico-sp',
							'_blank',
						)
					}>
					<FiExternalLink size={24} color='black' />
				</Button>
			</Tooltip>
			<Divider />
		</>
	)
}
const BotButtonsMobile = () => {
	let path = usePath()
	const bg = route =>
		path?.startsWith(route) ? 'rgba(180, 180, 180, 0.25)' : 'transparent'

	return (
		<>
			<Button
				w='100%'
				justifyContent='start'
				variant='ghost'
				bg={bg('/users')}
				_active={{
					bg: 'grey.100',
				}}
				onClick={_ => navigate('/users')}>
				<FiUsers size={24} color='black' />
				<Text ml='10px'>Utenti</Text>
			</Button>
			<Divider />

			<Button
				disabled
				w='100%'
				justifyContent='start'
				variant='ghost'
				_active={{
					bg: 'grey.100',
				}}
				onClick={_ => navigate('/permissions')}>
				<FiSettings size={24} color='black' />
				<Text ml='10px'>Permissions</Text>
			</Button>
			<Divider />

			<Button
				w='100%'
				justifyContent='start'
				variant='ghost'
				_active={{
					bg: 'grey.100',
				}}
				onClick={_ =>
					window.open(
						'https://cloud.digitalocean.com/spaces/trico-sp',
						'_blank',
					)
				}>
				<FiExternalLink size={24} color='black' />
				<Text ml='10px'>Images</Text>
			</Button>
			<Divider />
		</>
	)
}

const GlobalStyles = css`
	/*
    This will hide the focus indicator if the element receives focus    via the mouse,
    but it will still show up on keyboard focus.
  */
	.js-focus-visible :focus:not([data-focus-visible-added]) {
		outline: none;
		box-shadow: none;
	}
`

const App = () => {
	const pages = useRoutes(router || {})
	return (
		<ChakraProvider>
			<Global styles={GlobalStyles} />
			<ProvideAuth>
				<SWRConfig
					value={{
						refreshInterval: 60000,
						fetcher: (...params) => api(...params).then(r => r.data),
					}}>
					<AppWrapper auth={useAuth}>{pages}</AppWrapper>
				</SWRConfig>
			</ProvideAuth>
		</ChakraProvider>
	)
}
export default App
