import React, { useState, useContext, createContext } from 'react'

import useDeepCompareEffect from 'use-deep-compare-effect'
import moment from 'moment-timezone'
import { useCookies } from 'react-cookie'

import network, { api, hadesApi, adminHadesApi, setToken } from '../lib/network'

const {
	cloudEnabled,
	subscriber,
	subscriber_id,
	admin_subscriber,
	admin_subscriber_id,
} = network

const authContext = createContext()

const _setHadesToken = async _ => {
	if (!cloudEnabled()) return
	await api
		.get('/hades_token', {
			params: {
				subscriber: subscriber(),
				subscriber_id: subscriber_id(),
			},
		})
		.then(response => {
			let { tkn } = response.data
			setToken(hadesApi, tkn)
		})

	if (!admin_subscriber() || !admin_subscriber_id()) return
	await api
		.get('/hades_token', {
			params: {
				subscriber: admin_subscriber(),
				subscriber_id: admin_subscriber_id(),
			},
		})
		.then(response => {
			let { tkn } = response.data
			setToken(adminHadesApi, tkn)
		})
}

/**
 *  Provider component that wraps the app and makes auth object
 *  -   available to any child component that calls useAuth()
 */
export function ProvideAuth({ children }) {
	const auth = useProvideAuth()
	return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

/**
 * Hook for child components to get the auth object
 */
export const useAuth = () => {
	return useContext(authContext)
}

/**
 * Provider hook that creates auth object and handles state
 */
function useProvideAuth() {
	const [error, setError] = useState(null)
	const [user, setUser] = useState(null)
	const [authenticated, setAuthenticated] = useState(false)
	const [fetching, setFetching] = useState(false)
	const [cookies, setCookies, removeCookies] = useCookies(['login'])

	const signin = ({ tenant, email, password, defaultRoute }) => {
		setFetching(true)
		api
			.post(`/signin`, { tenant, email, password })
			.then(({ data = {} }) => {
				setCookies(
					'login',
					{
						...data,
						defaultRoute,
					},
					{
						path: '/',
						expires: new Date(
							moment().add(1, 'M').format('YYYY-MM-DDTHH:mm:ssZ'),
						),
					},
				)
				setError(null)
			})
			.catch(error => {
				setFetching(false)
				setError({
					title: 'Login Fallito',
					description: error?.response?.data?.message || 'Errore sconosciuto',
				})
			})
	}

	const signup = (email, password) => {
		//TODO signup
	}

	const signout = () => {
		removeCookies('login', { path: '/' })
	}

	const sendPasswordResetEmail = email => {
		//TODO reset email
	}

	const confirmPasswordReset = (code, password) => {
		//TODO confirm reset
	}
	const consumeError = _ => setError(null)

	useDeepCompareEffect(() => {
		let loginCookie = cookies['login']

		if (loginCookie?._id) {
			setToken(api, loginCookie.token)
			_setHadesToken()
			setUser(loginCookie)
			setFetching(false)
		} else {
			setUser(null)
		}
	}, [cookies['login'] || {}])

	useDeepCompareEffect(
		_ => {
			let loginCookie = cookies['login']
			if (loginCookie?._id) setAuthenticated(true)
			else setAuthenticated(false)
		},
		[cookies['login'] || {}],
	)

	// Return the user object and auth methods
	return {
		user,
		authenticated,
		fetching,
		signin,
		signup,
		signout,
		sendPasswordResetEmail,
		confirmPasswordReset,
		error,
		consumeError,
	}
}
