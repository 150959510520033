import React, {
	useMemo,
	useState,
	useEffect,
	useRef,
	createRef,
	useImperativeHandle,
} from 'react'
import useSWR from 'swr'
import { api } from '@syn/core/lib/network'

import DayPicker from 'react-day-picker'
import 'react-day-picker/lib/style.css'
import TimePicker from 'rc-time-picker'
import 'rc-time-picker/assets/index.css'
import moment from 'moment-timezone'

import { useToast } from '@chakra-ui/react'

import {
	Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverCloseButton,
} from '@chakra-ui/react'
import {
	Textarea,
	Select,
	Editable,
	EditableInput,
	EditablePreview,
} from '@chakra-ui/react'
import {
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	NumberIncrementStepper,
	NumberDecrementStepper,
} from '@chakra-ui/react'
import {
	Flex,
	Box,
	Text,
	Button,
	ButtonGroup,
	IconButton,
	Divider,
	Heading,
} from '@chakra-ui/react'
import { Switch } from '@chakra-ui/react'
import { EditIcon } from '@chakra-ui/icons'

import JSONInput from 'react-json-editor-ajrm'

const pluralize = require('pluralize')
const formatD = t => moment(t).format('DD-MM-YYYY')
const formatH = t => moment(t).format('HH:mm:ss')

const DateEditor = ({ value, disabled, onChange }) => {
	let [date, setDate] = useState(new Date(value))
	let [time, setTime] = useState(moment(value))
	const [isOpen, setIsOpen] = React.useState(false)
	const open = () => setIsOpen(true)
	const close = () => setIsOpen(false)

	useEffect(() => {
		onChange(`${formatD(date)} ${formatH(time)}`)
	}, [date, time])

	return (
		<Flex justify='space-between'>
			<Text>
				{formatD(date)} {formatH(time)}
			</Text>
			<Popover
				usePortal
				isOpen={isOpen}
				onOpen={open}
				onClose={close}
				closeOnBlur={false}
				placement='right'>
				<PopoverTrigger>
					<IconButton
						marginLeft={5}
						isDisabled={disabled}
						size='sm'
						icon={<EditIcon />}
					/>
				</PopoverTrigger>
				<PopoverContent p={10}>
					<TimePicker
						style={{ width: 100 }}
						defaultValue={time}
						onChange={setTime}
					/>
					<DayPicker selectedDays={date} onDayClick={setDate} />
					<PopoverCloseButton />
				</PopoverContent>
			</Popover>
		</Flex>
	)
}
const NumberEditor = ({ value, disabled, onChange }) => {
	return (
		<NumberInput
			isDisabled={disabled}
			defaultValue={value}
			min={0}
			onChange={onChange}>
			<NumberInputField />
			<NumberInputStepper>
				<NumberIncrementStepper />
				<NumberDecrementStepper />
			</NumberInputStepper>
		</NumberInput>
	)
}
const BooleanEditor = ({ value = false, disabled, onChange }) => {
	let [check, setCheck] = useState(value)

	useEffect(() => {
		onChange(check)
	}, [check])

	return (
		<Switch
			color='teal'
			size='lg'
			isChecked={check}
			isDisabled={disabled}
			onChange={_ => setCheck(!check)}
		/>
	)
}
const StringEditor = ({ value = '', disabled, onChange }) => {
	return (
		<Textarea
			disabled={disabled}
			defaultValue={value}
			onChange={e => onChange(e.target.value)}
		/>
	)
}

const SelectEditor = ({ values, value, disabled, onChange }) => {
	return (
		<Select
			width=''
			isSearchable={true}
			defaultValue={value}
			isReadOnly={disabled}
			onChange={e => onChange(e.target.value)}>
			{values?.map((value, index) => (
				<option key={index} value={value}>
					{value}
				</option>
			))}
		</Select>
	)
}

const Editor = ({ dataRef, field }) => {
	let { disabled, key, instance, ref, embeddedInstance, enumValues, value } =
		field
	if (ref) value = value?._id

	const [result, setResult] = useState(value)
	const [json, setJson] = useState(
		['Mixed', 'Array'].includes(instance) ? value : {},
	)

	useEffect(
		_ => {
			if (disabled) return
			dataRef.current[key] = result
		},
		[result, disabled],
	)

	switch (instance) {
		case 'Number':
			return (
				<>
					<Flex direction={['column', 'row']} justify='space-between' mb='10px'>
						<Heading size='xs'>
							{key} [{instance}]
						</Heading>
						<NumberEditor
							disabled={disabled}
							value={value}
							onChange={setResult}
						/>
					</Flex>
					<Divider />
				</>
			)
		case 'Date':
			return (
				<>
					<Flex direction={['column', 'row']} justify='space-between' mb='10px'>
						<Heading size='xs'>
							{key} [{instance}]
						</Heading>
						<DateEditor
							disabled={disabled}
							value={value}
							onChange={setResult}
						/>
					</Flex>

					<Divider />
				</>
			)
		case 'Boolean':
			return (
				<>
					<Flex direction={['column', 'row']} justify='space-between' mb='10px'>
						<Heading size='xs'>
							{key} [{instance}]
						</Heading>
						<BooleanEditor
							disabled={disabled}
							value={value}
							onChange={setResult}
						/>
					</Flex>

					<Divider />
				</>
			)
		// case 'Array':
		// 	return (
		// 		<>
		// 			<Heading size='xs'>
		// 				{key} [{instance} - {embeddedInstance}]
		// 			</Heading>
		// 			<Flex p={10} direction='column'>
		// 				{value?.map(
		// 					(value, index) =>
		// 						({
		// 							String: (
		// 								<StringEditor
		// 									key={index}
		// 									disabled={disabled}
		// 									value={value}
		// 									onChange={value => setField(key, value)}
		// 								/>
		// 							),
		// 							Number: (
		// 								<NumberEditor
		// 									disabled={disabled}
		// 									value={value}
		// 									onChange={value => setField(key, value)}
		// 								/>
		// 							),
		// 							Date: (
		// 								<DateEditor
		// 									disabled={disabled}
		// 									value={value}
		// 									onChange={value => setField(key, value)}
		// 								/>
		// 							),
		// 						}[embeddedInstance]),
		// 				)}
		// 			</Flex>
		// 			<IconButton
		// 				size='sm'
		// 				icon='add'
		// 				isDisabled={disabled}
		// 				onClick={() => null}
		// 			/>
		// 			<Divider />
		// 		</>
		// 	)

		case 'Array':
		case 'Mixed':
			return (
				<>
					<Flex direction='column' mb='10px'>
						<Heading size='xs'>
							{key} [{instance}]
						</Heading>
						<Flex w='100%' justifyContent='flex-end'>
							<JSONInput
								id={`${key}`}
								height='300px'
								width='100%'
								waitAfterKeyPress={4000}
								theme='light_mitsuketa_tribute'
								colors={{ default: '#000000' }}
								placeholder={json}
								onChange={value => {
									setJson(value.jsObject)
									setResult(value.jsObject)
								}}
							/>
						</Flex>
					</Flex>
					<Divider />
				</>
			)
		default:
			if (enumValues?.length > 0) {
				return (
					<>
						<Flex
							direction={['column', 'row']}
							justify='space-between'
							mb='10px'>
							<Heading size='xs'>
								{key} [{instance}]
							</Heading>
							<SelectEditor
								disabled={disabled}
								values={enumValues}
								value={value}
								onChange={setResult}
							/>
						</Flex>
						<Divider />
					</>
				)
			}
			if (ref) {
				return (
					<>
						<Flex
							direction={['column', 'row']}
							justify='space-between'
							mb='10px'>
							<Heading size='xs'>
								{key} [ref - {ref}]
							</Heading>
							<Flex w={['100%', '50%']}>
								<StringEditor
									disabled={disabled}
									value={value}
									onChange={setResult}
								/>
							</Flex>
						</Flex>
						<Divider />
					</>
				)
			}
			return (
				<>
					<Flex direction={['column', 'row']} justify='space-between' mb='10px'>
						<Heading size='xs'>
							{key} [{instance}]
						</Heading>
						<Flex w={['100%', '50%']}>
							<StringEditor
								disabled={disabled}
								value={value}
								onChange={setResult}
							/>
						</Flex>
					</Flex>
					<Divider />
				</>
			)
	}
}

const ObjectDetails = ({ collection = '', id, mutate, onClose }) => {
	const toast = useToast()
	const dataRef = useRef({})

	collection = pluralize.singular(collection)
	const params = useMemo(
		() => ({
			params: {
				schema: true,
			},
		}),
		[],
	)
	const { data } = useSWR(collection ? [`/${collection}/${id}`, params] : null)
	let { __schema } = data || {}
	let { paths = {} } = __schema || {}

	let disabled_keys = [
		'_id',
		'__v',
		'updatedAt',
		'createdAt',
		'updatedBy',
		'createdBy',
	]
	let fields = []
	for (let key in paths) {
		let {
			options: { ref },
			instance,
			$embeddedSchemaType,
			enumValues,
			selected = true,
		} = paths[key]
		let { instance: embeddedInstance } = $embeddedSchemaType || {}
		let value = {
			key,
			instance,
			ref,
			embeddedInstance,
			enumValues,
			value: data[key],
			disabled: disabled_keys.includes(key) || selected === false,
		}
		fields.push(value)
	}

	return (
		<Flex
			key={id}
			h='100%'
			w='100%'
			p='20px'
			direction='column'
			overflowY='auto'>
			{fields.map((value, index) => (
				<Box key={index} mb='20px'>
					<Editor dataRef={dataRef} field={value} />
				</Box>
			))}
			<Flex w='100%' justify='center'>
				<Button
					variant='ghost'
					onClick={() => {
						console.log('saving', dataRef?.current)
						api
							.patch(`/${collection}/${id}`, dataRef?.current)
							.then(_ => {
								toast({
									title: 'Oggetto Modificato',
									status: 'success',
									isClosable: true,
									position: 'bottom-right',
								})
								mutate()
								onClose()
							})
							.catch(error => {
								toast({
									title: 'Errore richiesta',
									description: error,
									status: 'error',
									isClosable: true,
									position: 'bottom-right',
								})
							})
					}}>
					Edit
				</Button>
			</Flex>
		</Flex>
	)
}

export default ObjectDetails
