import React, { useState, useMemo, useRef, useImperativeHandle } from 'react'
import useSWR from 'swr'
import moment from 'moment-timezone'
import { useAuth } from '@syn/core/hooks/use-auth-single'

import {
	IconButton,
	Flex,
	Input,
	Button,
	Select,
	Box,
	Badge,
} from '@chakra-ui/react'

import {
	Alert,
	AlertIcon,
	AlertDialog,
	AlertDialogBody,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogContent,
	AlertDialogOverlay,
} from '@chakra-ui/react'

import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
} from '@chakra-ui/react'

import { Avatar } from '@chakra-ui/react'
import { EditIcon, DeleteIcon } from '@chakra-ui/icons'
import { useDisclosure, useToast } from '@chakra-ui/react'

import {
	Table,
	Tooltipable,
	Entry,
	Create,
	Edit,
	Delete,
} from '@syn/ui/components'

import { api } from '@syn/core/lib/network'
import { randomPassword } from '@syn/core/lib/utils'
moment.tz.setDefault('Europe/Rome')
const roles = ['admin', 'user']

const CreateItem = ({ dataRef, showPassword = true }) => {
	let [img, setImg] = useState('')
	let [name, setName] = useState('')
	let [lastname, setLastname] = useState('')
	let [email, setEmail] = useState('')
	let [role, setRole] = useState('')
	const [password, setPassword] = useState(randomPassword(8))

	useImperativeHandle(
		dataRef,
		() => ({
			img,
			name,
			lastname,
			email,
			roles: [role],
			password,
		}),
		[img, name, lastname, email, role, password],
	)

	let msg = showPassword
		? `La password di default impostata è: ${password}`
		: "Verrà inviata una mail con la password temporanea all'utente creato"
	return (
		<>
			<Alert status='warning'>
				<AlertIcon />
				{msg}
			</Alert>

			<Entry label='Nome'>
				<Input
					placeholder='Nome utente'
					value={name}
					onChange={e => setName(e.target.value)}
				/>
			</Entry>
			<Entry label='Cognome'>
				<Input
					placeholder='Cognome utente'
					value={lastname}
					onChange={e => setLastname(e.target.value)}
				/>
			</Entry>
			<Entry label='Email'>
				<Input
					placeholder='Email utente'
					value={email}
					onChange={e => setEmail(e.target.value)}
				/>
			</Entry>
			<Entry label='Ruolo'>
				<Select
					placeholder='Nessun Ruolo'
					onChange={e => setRole(e.target.value)}>
					{roles?.map((value, index) => (
						<option key={index} value={value}>
							{value}
						</option>
					))}
				</Select>
			</Entry>
		</>
	)
}

const EditItem = ({ dataRef, id }) => {
	//TODO retrieve current role
	let [role, setRole] = useState('')
	useImperativeHandle(
		dataRef,
		() => ({
			role,
		}),
		[role],
	)
	return (
		<>
			<Entry label='Ruolo'>
				<Select
					placeholder='Nessun Ruolo'
					defaultValue={role}
					onChange={e => setRole(e.target.value)}>
					{roles?.map((value, index) => (
						<option key={index} value={value}>
							{value}
						</option>
					))}
				</Select>
			</Entry>
		</>
	)
}

const List = () => {
	//TODO create a lib function
	const { user } = useAuth()
	let { data: userData } = useSWR([user?._id ? `/user/${user?._id}` : null])
	let _role = userData?.roles?.filter(x => x !== user?._id)[0] || 'Nessun ruolo'

	const toast = useToast()

	const disclosureEdit = useDisclosure()
	const disclosureCreate = useDisclosure()
	const disclosureDelete = useDisclosure()

	let [editId, setEditId] = useState()
	let [deleteId, setDeleteId] = useState()
	let [search, setSearch] = useState()

	const editRef = useRef(null)
	const createRef = useRef(null)

	const params = useMemo(
		() => ({
			params: { search },
		}),
		[search],
	)
	let { data, mutate } = useSWR([`/users`, params])

	return (
		<>
			<Flex justify='flex-end' p={1}>
				<Flex>
					<Input
						placeholder='Cerca Utente'
						onChange={e => setSearch(e.target.value)}
					/>
				</Flex>

				<Button
					isDisabled={!['superadmin', 'admin'].includes(_role)}
					variant='ghost'
					onClick={disclosureCreate.onOpen}>
					Aggiungi Utente
				</Button>
			</Flex>
			<Table.Container fetching={!data}>
				<Table.Header>
					<Tooltipable bold text={'Avatar'} />
					<Tooltipable bold text={'Nome'} />
					<Tooltipable bold text={'Cognome'} />
					<Tooltipable bold text={'Email'} />
					<Tooltipable bold text={'Ruolo'} />
					<Box />
				</Table.Header>
				{data?.map(value => {
					let { _id, img, name, lastname, email, roles } = value
					let role = roles.filter(x => x !== _id)[0] || 'Nessun ruolo'

					return (
						<Table.Row key={_id}>
							<Avatar name={`${name} ${lastname}`} src={img} />
							<Tooltipable text={name} />
							<Tooltipable text={lastname} />
							<Tooltipable text={email} />
							<Box>
								<Badge ml={3} fontSize='xs'>
									<Tooltipable text={role} />
								</Badge>
							</Box>
							<Flex justify='flex-end'>
								<IconButton
									isDisabled={!['superadmin', 'admin'].includes(_role)}
									icon={<EditIcon />}
									onClick={() => {
										setEditId(_id)
										disclosureEdit.onOpen()
									}}
								/>
								<IconButton
									icon={<DeleteIcon />}
									colorScheme='red'
									onClick={() => {
										setDeleteId(_id)
										disclosureDelete.onOpen()
									}}
								/>
							</Flex>
						</Table.Row>
					)
				})}
			</Table.Container>
			<Create
				title='Creazione Utente'
				disclosure={disclosureCreate}
				onCreate={_ => {
					api
						.post('/user', {
							...createRef?.current,
						})
						.then(_ => {
							mutate()
							toast({
								title: 'Oggetto Creato',
								status: 'success',
								isClosable: true,
								position: 'bottom-right',
							})
						})
						.catch(error => {
							toast({
								title: 'Errore richiesta',
								description:
									error?.response?.data?.message || 'Errore sconosciuto',
								status: 'error',
								isClosable: true,
								position: 'bottom-right',
							})
						})
				}}>
				<CreateItem dataRef={createRef} />
			</Create>

			<Edit
				title='Modifica Utente'
				disclosure={disclosureEdit}
				onEdit={_ => {
					let newRoles = [editRef?.current?.role]
					api
						.patch(`user/${editId}`, { roles: newRoles })
						.then(_ => {
							mutate()
							toast({
								title: 'Utente Modificato',
								status: 'success',
								isClosable: true,
								position: 'bottom-right',
							})
						})
						.catch(error => {
							toast({
								title: 'Errore richiesta',
								description:
									error?.response?.data?.message || 'Errore sconosciuto',
								status: 'error',
								isClosable: true,
								position: 'bottom-right',
							})
						})
				}}>
				<EditItem dataRef={editRef} id={editId} />
			</Edit>
			<Delete
				mutate={mutate}
				collection='/user'
				id={deleteId}
				params={{ hard: true }}
				title='Rimuovi Utente'
				text={`Sei sicuro di voler rimuovere l'utente?`}
				disclosure={disclosureDelete}
			/>
		</>
	)
}
export default List
