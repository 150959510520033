import { useEffect, useState } from 'react'
import { navigate, usePath, getQueryParams } from 'hookrouter'

const useRequireAuth = (authHook, loginPath = '/login') => {
	const queryParams = getQueryParams()
	const [_fetching, _setFetching] = useState(true)
	const [prevPathname, setPrevPathname] = useState()
	const { fetching, user, authenticated } = authHook()
	let pathname = usePath()

	useEffect(() => {
		_setFetching(fetching)
	}, [fetching])

	useEffect(
		_ => {
			if (user?.defaultRoute !== '/' && '/' === pathname?.toLowerCase()) return
			if (loginPath?.toLowerCase() === pathname?.toLowerCase()) return
			setPrevPathname(pathname)
		},
		[pathname, user],
	)

	useEffect(() => {
		if (_fetching) return
		if (!authenticated) {
			navigate(loginPath)
		} else {
			let { defaultRoute = '/' } = user || {}
			if (defaultRoute?.toLowerCase() !== prevPathname?.toLowerCase()) {
				defaultRoute = prevPathname || defaultRoute
			}
			navigate(defaultRoute, false, queryParams)
		}
	}, [_fetching, user, authenticated])

	return _fetching
}
export default useRequireAuth
