import moment from 'moment-timezone'

export const capitalize = (str = '') => {
	return str.charAt(0).toUpperCase() + str.slice(1)
}

export const randomPassword = length => {
	const chars =
		'abcdefghijklmnopqrstuvwxyz!@#$%^&*()-+<>ABCDEFGHIJKLMNOP1234567890'
	let psw = ''
	for (let i = 0; i < length; i++) {
		let c = Math.floor(Math.random() * chars.length)
		psw += chars.charAt(c)
	}
	return psw
}

export function join(/* path segments */) {
	// Split the inputs into a list of path commands.
	let parts = []
	for (let arg of arguments) {
		parts = parts.concat(arg.split('/'))
	}
	// Interpret the path commands to get the new resolved path.
	let newParts = []
	for (let part of parts) {
		// Remove leading and trailing slashes
		// Also remove "." segments
		if (!part || part === '.') continue
		// Interpret ".." to pop the last segment
		if (part === '..') newParts.pop()
		// Push new path segments.
		else newParts.push(part)
	}

	// Preserve the initial slash if there was one.
	if (parts[0] === '') newParts.unshift('')
	// Turn back into a single string path.
	return newParts.join('/') || (newParts.length ? '/' : '.')
}

/** In case duration changes
	
	let dunit = 1000 * 60 * 60 * 24
	let d = time / dunit
	let days = Math.floor(d)
	let h = (d - days) * 24
	let hours = Math.floor(h)
	let m = (h - hours) * 60
	let minutes = Math.floor(m)
	let s = (m - minutes) * 60
	let seconds = Math.round(s)

	return {
		days,
		hours,
		minutes,
		seconds,
	}
*/
export const parseTime = time => {
	let dur = moment.duration(time)
	return dur._data
}
export const displayDuration = duration => {
	let { days, hours, minutes, seconds } = duration
	let d = days ? `${days}d` : ''
	let h = hours ? `${hours}h` : ''
	let m = minutes ? `${minutes}m` : ''
	let s = `${seconds}s`
	return `${d} ${h} ${m} ${s}`
}

export const lotSplitting = (lotString, currentQuantity) => {
	const splitting = lotString?.split(',') || []
	const totalLotSum = splitting?.reduce((sum, val) => sum + parseInt(val), 0)

	let lot = 1
	let paritalSum = 0
	let lotDone = 0
	let [lotTodo] = splitting || [0]
	let diff = currentQuantity || 0

	for (let lotQty of splitting) {
		let _qty = parseInt(lotQty)
		if (isNaN(_qty)) continue
		paritalSum += _qty
		lotTodo = _qty
		diff -= _qty
		if (diff < 0) break
		lot += 1
	}

	lotDone = lotTodo - (paritalSum - currentQuantity) || 0
	if (totalLotSum <= currentQuantity) {
		return {
			lot,
			lotDone: 0,
			todoLot: 0,
		}
	}
	return {
		lot,
		lotDone,
		lotTodo,
	}
}
export const qcSelection = (quantity = 0, qc, samples) => {
	if (!qc || !samples)
		return {
			sampleN: 1,
			nextSample: Infinity,
		}
	const sampleDone = samples?.length
	const sampleN = sampleDone + 1
	const sampleRatio = Math.round(quantity / qc)
	const randomInterval = Math.floor(Math.random() * sampleRatio) + 1
	const nextSample = sampleDone * sampleRatio + randomInterval

	return {
		sampleN,
		nextSample,
	}
}
