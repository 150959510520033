import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Measure from 'react-measure'
import { Tooltip, Text } from '@chakra-ui/react'

const Tooltipable = ({ bold, text, ...rest }) => {
	const [openTooltip, setOpenTooltip] = useState(false)

	return (
		<Measure offset scroll>
			{({ measureRef, contentRect: { offset, scroll } }) => {
				const truncated = offset.width < scroll.width
				return (
					<Text
						ref={measureRef}
						as={bold ? 'b' : null}
						isTruncated
						onMouseEnter={() => setOpenTooltip(true)}
						onMouseLeave={() => setOpenTooltip(false)}
						onClick={() => setOpenTooltip(open => !open)}
						{...rest}>
						{truncated ? (
							<Tooltip isOpen={openTooltip} label={text} placement='top'>
								{text}
							</Tooltip>
						) : (
							text
						)}
					</Text>
				)
			}}
		</Measure>
	)
}

Tooltipable.propTypes = {
	bold: PropTypes.bool,
	text: PropTypes.string,
}

export default Tooltipable
