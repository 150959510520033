import React from 'react'
import { useRoutes } from 'hookrouter'
import { SWRConfig } from 'swr'
import { api } from '@syn/core/lib/network'

import List from './List'
import Detail from './Detail'

const router = {
	[`/`]: () => <List />,
	[`/:id`]: ({ id }) => <Detail id={id} />,
}
function Main({ customSwr, interval = 60000 }) {
	const pages = useRoutes(router || {})
	if (!pages) return null
	if (!!!customSwr) return pages

	return (
		<SWRConfig
			value={{
				refreshInterval: interval,
				fetcher: (...params) => {
					if (params[0] === null) return
					if (params[2]) return params[2](...params).then(r => r.data)
					return api(...params).then(r => r.data)
				},
			}}>
			{pages}
		</SWRConfig>
	)
}
export default Main
