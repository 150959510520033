import React, { useEffect, useRef, useState, createRef } from 'react'
import {
	Flex,
	Box,
	Stack,
	SimpleGrid,
	Skeleton,
	Button,
} from '@chakra-ui/react'
import useWindowSize, { breakpoints } from '@syn/core/hooks/use-window-size'
import VisibilitySensor from 'react-visibility-sensor'
import { UnmountClosed } from 'react-collapse'

import {
	useBoolean,
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	AccordionIcon,
} from '@chakra-ui/react'

import { VariableSizeList, FixedSizeList } from 'react-window'

const ITEM_WIDTH = 120
const ROW_HEIGHT = 68
const PANEL_MAX_HEIGHT = 900

const Header = ({ children, ...rest }) => {
	return (
		<SimpleGrid
			columns={children?.length}
			alignItems='center'
			p='10px'
			shadow='md'
			spacingX='10px'
			{...rest}>
			{children}
		</SimpleGrid>
	)
}

const Row = ({ index, children, ...rest }) => {
	return (
		<SimpleGrid
			columns={children?.length}
			alignItems='center'
			p='10px'
			spacingX='10px'
			_hover={{ cursor: rest?.onClick ? 'pointer' : 'auto' }}
			{...rest}>
			{children}
		</SimpleGrid>
	)
}

const AccordionItemRow = ({
	index,
	accordionListRef,
	children,
	details,
	...rest
}) => {
	const [open, setOpen] = useBoolean(false)
	const bg = open ? 'gray.100' : 'transparent'

	useEffect(
		_ => {
			setTimeout(_ => {
				accordionListRef?.current?.resetAfterIndex(index)
			}, 1)
		},
		[open],
	)
	return (
		<Box p='10px' w='100%' bg={bg} _hover={{ bg: 'gray.200' }} {...rest}>
			<SimpleGrid
				w={`calc(100% - 10px)`}
				columns={children?.length}
				alignItems='center'
				spacingX='10px'
				textAlign='left'
				_hover={{ cursor: 'pointer' }}
				onClick={_ => {
					accordionListRef?.current?.resetAfterIndex(index)
					setOpen.toggle()
				}}>
				{children}
			</SimpleGrid>
			<UnmountClosed isOpened={open}>
				<Box p='10px' position='relative' maxH={PANEL_MAX_HEIGHT}>
					{details}
				</Box>
			</UnmountClosed>
		</Box>
	)
}

const AccordionItemRowMobile = ({
	index,
	accordionListRef,
	headerChildren,
	rowChildren,
	details,
	...rest
}) => {
	const [open, setOpen] = useBoolean(false)
	const bg = open ? 'gray.100' : 'transparent'

	return (
		<Box
			p='10px'
			w='100%'
			bg={bg}
			_hover={{ cursor: 'pointer', bg: 'gray.200' }}
			{...rest}
			onClick={_ => {
				setOpen.toggle()
				accordionListRef?.current?.resetAfterIndex(index)
				setTimeout(_ => {
					accordionListRef?.current?.resetAfterIndex(index)
				}, 100)
			}}>
			<Stack w='100%' spacing='10px' p='20px'>
				{headerChildren?.map((_, idx) => (
					<Flex key={index} justify='space-between'>
						<Flex w='30%'>{headerChildren[idx]}</Flex>
						<Flex w='65%' justify='flex-end'>
							{rowChildren[idx]}
						</Flex>
					</Flex>
				))}
			</Stack>
			<UnmountClosed isOpened={open}>
				<Box p='10px' position='relative' maxH={PANEL_MAX_HEIGHT}>
					{details}
				</Box>
			</UnmountClosed>
		</Box>
	)
}

const Container = ({
	fetching = false,
	children = [],
	minW,
	fullH = false,
	windowScroll = false,
	fallback = null,
	...rest
}) => {
	const accordionListRef = createRef()
	const accordionItemsRef = (createRef().current = [])
	children = children.flat()

	let header = children?.find(x => x?.type?.name === Header.name)
	let { children: headerChildren } = header?.props || {}
	let rows = children?.filter(x => x?.type?.name === Row.name)
	let accordionRows = children?.filter(
		x => x?.type?.name === AccordionItemRow.name,
	)

	let { width, height } = useWindowSize()
	if (width <= breakpoints.md) {
		const ROW_HEIGHT_MOBILE =
			30 * headerChildren?.length + 10 * headerChildren?.length - 1 + 40
		return (
			<>
				{fetching && (
					<Stack>
						<Skeleton height='20px' />
						<Skeleton height='20px' />
						<Skeleton height='20px' />
					</Stack>
				)}
				{fallback}

				{accordionRows?.map((value, index) => {
					let { children: rowChildren, details, ...rest } = value?.props || {}
					return (
						<AccordionItemRowMobile
							key={index}
							index={index}
							headerChildren={headerChildren}
							rowChildren={rowChildren}
							details={details}
							shadow='sm'
							{...rest}
						/>
					)
				})}

				{rows?.length > 0 && (
					<FixedSizeList
						onScroll={({ scrollDirection, scrollOffset }) => {
							if (!scrollOffset || !windowScroll) return
							if (scrollDirection === 'forward') {
								window.scrollBy(0, 200)
							} else {
								window.scrollBy(0, -200)
							}
						}}
						height={
							fullH
								? ROW_HEIGHT_MOBILE * rows.length
								: Math.min(ROW_HEIGHT_MOBILE * rows.length, height)
						}
						itemCount={rows.length}
						itemSize={ROW_HEIGHT_MOBILE}
						width='100%'>
						{({ index, style }) => {
							let { onClick, children: rowChildren } = rows[index]?.props || {}
							return (
								<Box style={style} shadow='sm'>
									<Stack key={index} spacing='10px' p='20px' onClick={onClick}>
										{headerChildren?.map((_, index) => (
											<Flex key={index} h='30px' justify='space-between'>
												<Flex w='30%'>{headerChildren[index]}</Flex>
												<Flex maxW='65%' justify='flex-end'>
													{rowChildren[index]}
												</Flex>
											</Flex>
										))}
									</Stack>
								</Box>
							)
						}}
					</FixedSizeList>
				)}
			</>
		)
	}
	const childrenWidth = headerChildren?.length * ITEM_WIDTH
	let _minW = width - 64
	if (childrenWidth > _minW) _minW = childrenWidth
	return (
		<Flex w='100%' overflowX='auto' {...rest}>
			<Flex minW={minW || _minW} direction='column'>
				{header}
				{fetching && (
					<Stack>
						<Skeleton height='20px' />
						<Skeleton height='20px' />
						<Skeleton height='20px' />
					</Stack>
				)}
				{fallback}
				{accordionRows?.length > 0 && (
					<VariableSizeList
						ref={accordionListRef}
						onScroll={({ scrollDirection, scrollOffset }) => {
							if (!scrollOffset || !windowScroll) return
							if (scrollDirection === 'forward') {
								window.scrollBy(0, 200)
							} else {
								window.scrollBy(0, -200)
							}
						}}
						height={height}
						itemCount={accordionRows?.length}
						itemSize={index => {
							return accordionItemsRef?.[index]?.clientHeight || 68
						}}
						width='100%'>
						{({ index, style }) => {
							return (
								<Box style={style} shadow='sm'>
									<Box
										ref={ref => {
											accordionItemsRef[index] = ref
										}}
										w='100%'>
										{React.cloneElement(accordionRows[index], {
											index,
											accordionListRef,
										})}
									</Box>
								</Box>
							)
						}}
					</VariableSizeList>
				)}
				{rows?.length > 0 && (
					<FixedSizeList
						onScroll={({ scrollDirection, scrollOffset }) => {
							if (!scrollOffset || !windowScroll) return
							if (scrollDirection === 'forward') {
								window.scrollBy(0, 200)
							} else {
								window.scrollBy(0, -200)
							}
						}}
						height={
							fullH
								? ROW_HEIGHT * rows.length
								: Math.min(ROW_HEIGHT * rows.length, height)
						}
						itemCount={rows.length}
						itemSize={ROW_HEIGHT}
						width='100%'>
						{({ index, style }) => {
							return (
								<Flex
									direction='column'
									justify='center'
									style={style}
									shadow='sm'>
									{React.cloneElement(rows[index], { index })}
								</Flex>
							)
						}}
					</FixedSizeList>
				)}
			</Flex>
		</Flex>
	)
}

const Table = {
	Container,
	Header,
	Row,
	AccordionItemRow,
}
export default Table
