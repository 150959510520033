import React, { useState, useEffect } from 'react'
import useSWR from 'swr'
import moment from 'moment-timezone'
import {
	ButtonGroup,
	IconButton,
	Flex,
	Divider,
	InputGroup,
	Input,
	InputRightElement,
	Button,
	Heading,
	Stack,
	HStack,
} from '@chakra-ui/react'
import {
	Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverArrow,
} from '@chakra-ui/react'
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
} from '@chakra-ui/react'

import { Avatar, AvatarBadge, Tag } from '@chakra-ui/react'
import { EditIcon } from '@chakra-ui/icons'
import { useDisclosure, useToast } from '@chakra-ui/react'

import { Tooltipable, Entry } from '@syn/ui/components'
import { api } from '@syn/core/lib/network'
import { FiLock, FiEye, FiEyeOff } from 'react-icons/fi'
moment.tz.setDefault('Europe/Rome')

const EditPassword = ({ disclosure: { isOpen, onClose } }) => {
	const toast = useToast()
	let [old, setOld] = useState('')
	let [showOld, setShowOld] = useState(false)
	let [password, setPassword] = useState('')
	let [showPassword, setShowPassword] = useState(false)
	let [repete, setRepete] = useState('')
	let [showRepete, setShowRepete] = useState(false)

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Modifica Password</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Stack p={5} spacing={10}>
						<Entry label='Password precedente'>
							<InputGroup>
								<Input
									type={showOld ? 'text' : 'password'}
									placeholder='vecchia pwd'
									value={old}
									onChange={e => setOld(e.target.value)}
								/>
								<InputRightElement
									children={
										showOld ? (
											<IconButton
												variant='ghost'
												colorScheme='blue'
												aria-label='hide'
												icon={<FiEyeOff />}
												onClick={_ => setShowOld(false)}
											/>
										) : (
											<IconButton
												variant='ghost'
												colorScheme='blue'
												aria-label='show'
												onClick={_ => setShowOld(true)}
												icon={<FiEye />}
											/>
										)
									}
								/>
							</InputGroup>
						</Entry>
						<Entry label='Password attuale'>
							<InputGroup>
								<Input
									type={showPassword ? 'text' : 'password'}
									placeholder='pwd'
									value={password}
									onChange={e => setPassword(e.target.value)}
								/>
								<InputRightElement
									children={
										showPassword ? (
											<IconButton
												variant='ghost'
												colorScheme='blue'
												aria-label='hide'
												icon={<FiEyeOff />}
												onClick={_ => setShowPassword(false)}
											/>
										) : (
											<IconButton
												variant='ghost'
												colorScheme='blue'
												aria-label='show'
												onClick={_ => setShowPassword(true)}
												icon={<FiEye />}
											/>
										)
									}
								/>
							</InputGroup>
						</Entry>
						<Entry label='Ripetere password'>
							<InputGroup>
								<Input
									type={showRepete ? 'text' : 'password'}
									placeholder='pwd'
									value={repete}
									onChange={e => setRepete(e.target.value)}
								/>
								<InputRightElement
									children={
										showRepete ? (
											<IconButton
												variant='ghost'
												colorScheme='blue'
												aria-label='hide'
												icon={<FiEyeOff />}
												onClick={_ => setShowRepete(false)}
											/>
										) : (
											<IconButton
												variant='ghost'
												colorScheme='blue'
												aria-label='show'
												onClick={_ => setShowRepete(true)}
												icon={<FiEye />}
											/>
										)
									}
								/>
							</InputGroup>
						</Entry>
					</Stack>
				</ModalBody>

				<ModalFooter>
					<Button colorScheme='blue' mr={3} onClick={onClose}>
						Annulla
					</Button>
					<Button
						disabled={!password || password !== repete}
						variant='ghost'
						onClick={() => {
							api
								.post(`passwd`, { oldPassword: old, password })
								.then(_ => {
									onClose()
									toast({
										title: 'Password modificata',
										status: 'success',
										isClosable: true,
										position: 'bottom-right',
									})
								})
								.catch(error => {
									toast({
										title: 'Errore richiesta',
										description:
											error?.response?.data?.message || 'Errore sconosciuto',
										status: 'error',
										isClosable: true,
										position: 'bottom-right',
									})
								})
						}}>
						Modifica
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}

const Detail = ({ id }) => {
	const toast = useToast()

	const disclosureEditName = useDisclosure()
	const disclosureChangePWD = useDisclosure()

	let [name, setName] = useState('')
	let [lastname, setLastname] = useState('')

	let { data, mutate } = useSWR([id ? `/user/${id}` : null])

	let { email, roles = [] } = data || {}
	let role = roles.filter(x => x !== id)[0] || 'Nessun ruolo'

	useEffect(() => {
		if (!data) return
		if (!name) setName(data?.name)
		if (!lastname) setLastname(data?.lastname)
	}, [data])

	return (
		<Flex h='100%' w='100%' direction='column' align='center'>
			<Flex w='100%' h='200px' align='center' justify='center'>
				<Divider w='40%' />
				<Tag colorScheme='red' m={4} p='20px 40px' rounded='full'>
					<HStack
						spacing='10px'
						w='300px'
						align='center'
						justify='center'
						flexWrap='wrap'>
						<Avatar size='2xl' name={`${data?.name} ${data?.lastname}`}>
							<AvatarBadge as={Button} bg='white' disabled>
								<EditIcon color='black' />
							</AvatarBadge>
						</Avatar>
						<Tooltipable bold text={role} />
					</HStack>
				</Tag>
				<Divider w='40%' />
			</Flex>
			<Flex align='center'>
				<Heading mr='10px'>
					{data?.name} {data?.lastname}
				</Heading>
				<Popover
					placement='bottom'
					isOpen={disclosureEditName.isOpen}
					onOpen={disclosureEditName.onOpen}
					onClose={disclosureEditName.onClose}
					closeOnBlur={false}>
					<PopoverTrigger>
						<IconButton size='sm' icon={<EditIcon />} />
					</PopoverTrigger>
					<PopoverContent zIndex={4} p={8}>
						<PopoverArrow bg='white' />
						<Stack spacing={4}>
							<Entry vertical label='Nome'>
								<Input value={name} onChange={e => setName(e.target.value)} />
							</Entry>
							<Entry vertical label='Cognome'>
								<Input
									value={lastname}
									onChange={e => setLastname(e.target.value)}
								/>
							</Entry>

							<ButtonGroup d='flex' justifyContent='flex-end'>
								<Button
									variant='outline'
									onClick={_ => {
										setName(data?.name)
										setLastname(data?.lastname)
										disclosureEditName.onClose()
									}}>
									Annulla
								</Button>
								<Button
									colorScheme='teal'
									onClick={_ => {
										api
											.patch(`user/${id}`, { name, lastname })
											.then(_ => {
												mutate()
												toast({
													title: 'Dati utente modificati',
													status: 'success',
													isClosable: true,
													position: 'bottom-right',
												})
											})
											.catch(error => {
												toast({
													title: 'Errore richiesta',
													description:
														error?.response?.data?.message ||
														'Errore sconosciuto',
													status: 'error',
													isClosable: true,
													position: 'bottom-right',
												})
											})
										disclosureEditName.onClose()
									}}>
									Salva
								</Button>
							</ButtonGroup>
						</Stack>
					</PopoverContent>
				</Popover>
			</Flex>
			<Heading size='lg'>{email}</Heading>
			<Button
				mt='40px'
				rightIcon={<FiLock />}
				colorScheme='red'
				variant='outline'
				onClick={disclosureChangePWD.onOpen}>
				Cambia password
			</Button>
			<EditPassword disclosure={disclosureChangePWD} />
		</Flex>
	)
}
export default Detail
